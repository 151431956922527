import React from "react";
import { Link } from "gatsby";

const DynamicPost = ({ news }) => {
  return (
    <div
      className="tile-container"
      style={{
        width: "calc(50% - 10px)",
        transform: "rotate(180deg)",
      }}
    >
      <div
        className="info-tile  --align-left  full-height-container"
        style={{ backgroundColor: "#fff" }}
      >
        <span>{new Date(news?.createdAt)?.toLocaleDateString()}</span>

        <div className="icon-container">
          <i className="tile-icon icon icon-newspaper " />
        </div>
        <p className="section-title-small --align-left ">{news?.title}</p>
        <p className="section-description --align-left ">{news?.description}</p>
        <Link to={news?.slug} className="learn-more-news">
          LEARN MORE
        </Link>
      </div>
    </div>
  );
};

export default DynamicPost;
