import React from "react";

const NewsHead = () => {
  return (
    <div className="row  page__section --grid-1-1 --align-left">
      <div>
        <h1 className="section-title">News</h1>
        <p className="section-description">
          Welcome to S&T News. Here you can read the last updates on our
          activities
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <i className="icon icon-newspaper" />
      </div>
    </div>
  );
};

export default NewsHead;
