import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import DynamicPost from "./news/DynamicPost";
import NewsHP from "./news/HP/NewsHP";
import NewsHead from "./news/NewsHead";

const News = ({ data }) => {
  const initialList = data.posts.edges
    .map((post) => post.node)
    .sort(
      (objA, objB) =>
        Number(new Date(objA.createdAt?.split("T")[0])) -
        Number(new Date(objB.createdAt?.split("T")[0])),
    );

  return (
    <Layout>
      <div className="page">
        <NewsHead />
        <div className="block--gray news-block-gray">
          <div className="row page__section news__content-section">
            {oldPosts?.map((news) => (
              <NewsHP key={news.id} news={news} old={true} />
            ))}
            {initialList.map((news) => (
              <DynamicPost news={news} />
            ))}
            {/* {hpPosts?.map((news) => (
              <NewsHP key={news.id} news={news} />
            ))} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default News;

export const query = graphql`
  query {
    posts: allContentfulPost {
      edges {
        node {
          title
          description
          slug
          createdAt
          richText {
            raw
          }
        }
      }
    }
  }
`;

export const oldPosts = [
  {
    id: "25062021",
    title: `Invitation to the S&T Mold FORUM INFO SECURITY 2021`,
    date: "21.04.2021",
    description: `Wednesday, July 07, 2021 |10:00| Grand Elysee`,
  },
  {
    id: "21042021",
    title: `Benefits of Oracle Database for Your Data`,
    date: "21.04.2021",
    description: `Oracle database products offer customers cost-optimized and
    high-performance versions`,
  },
  {
    id: "09032021",
    title: `GOOD START OF FINANCIAL YEAR 2021`,
    date: "09.03.2021",
    description: `Strong order intake in the first weeks of financial year 2021`,
  },
  {
    id: "16032021",
    title: `CATCHING TECHNOLOGICAL WAVES`,
    date: "16.03.2021",
    description: `According to a UNCTAD Technology and Innovation Report in
    2021, Moldova among the top 10 developing countries`,
  },
  {
    id: "13012021",
    title: `HANNES NIEDERHAUSER, CEO S&T AG, ON TRENDS 2021`,
    date: "13.01.2021",
    description: `HANNES NIEDERHAUSER, CEO S&T AG, ON TRENDS 2021 "NEW STANDARDS
    ACCELERATE DEVELOPMENTS FOR HIGH-PERFORMANCE COMPUTING AND
    SAFETY"`,
  },
  {
    id: "19012021",
    title: `ORACLE DATABASE - IDEAL PLATFORM FOR DATA MANAGEMENT AND
    ARTIFICIAL INTELLIGENCE`,
    date: "19.01.2021",
    description: `The amount of data we produce every day is truly
    mind-boggling. 2.5 quintillion bytes of data are generated`,
  },
  {
    id: "10022021",
    title: `ENSURING BUSINESS CONTINUITY AND DISASTER RECOVERY WITH ORACLE
    OBJECT STORAGE`,
    date: "01.02.2021",
    description: `S&T Mold invites you to take part in the ERC partner webinar.
    In our webinar, we will look at`,
  },
  {
    id: "23022021",
    title: `PRELIMINARY FIGURES 2020: S&T CONTINUES PROFITABLE REVENUE
    GROWTH DESPITE CORONA CRISIS`,
    date: "23.02.2021",
    description: `Revenues increased by 11.7% to EUR 1,254.8 million (PY: EUR
      1,122.9 million)`,
  },
  {
    id: "22022020",
    title: `2020 S&T AG: PROFIT (EBITDA) TO EXCEED 10% IN 2020 FOR THE
    FIRST TIME`,
    date: "22.01.2020",
    description: `S&T AG plans to continue its strong growth in the 2020
    financial year and has announced its ambitious targets for the
    current year`,
  },
  {
    id: "26032020",
    title: `S&T AG: 2019 - NINTH RECORD YEAR IN SUCCESSION`,
    date: "26.03.2020",
    description: `The technology group S&T AG presents record results for the
    ninth year in a row. The company recorded a 13% increase`,
  },
  {
    id: "07052020",
    title: `S&T AG: STRONG Q1 2020 DESPITE CORONA CRISIS`,
    date: "07.05.2020",
    description: `Linz, 07.05.2020. The technology group S&T AG is delivering
    its 45th record quarter in a row at the start of the year`,
  },
  {
    id: "06082020",
    title: `S&T AG DEFIES THE CORONA CRISIS WITH A SUCCESSFUL Q2`,
    date: "06.08.2020",
    description: `The technology group S&T AG (ISIN: AT0000A0E9W5, WKN: A0X9EJ,
      ticker symbol: SANT) successfully closed the first half of
      2020`,
  },
  {
    id: "01092020",
    title: `„TEKWILL IN EVERY SCHOOL”!`,
    date: "01.09.2020",
    description: `Both Google and S&T Mold believe in the power of education in
    Moldova and joined the project "Tekwill in every school"`,
  },
  {
    id: "05112020",
    title: `S&T AG: STRONG 3RD QUARTER - FORECAST RAISED`,
    date: "05.11.2020",
    description: `The technology group S&T AG (ISIN: AT0000A0E9W5, WKN: A0X9EJ,
      stock exchange symbol: SANT) continues to grow in Q3`,
  },
  {
    id: "23112020",
    title: `S&T MOLD CELEBRATED ITS 25TH ANNIVERSARY`,
    date: "23.11.2020",
    description: `The company S&T Mold was founded in 1995 and has rightfully
    won a leading position in Moldovan Market of system
    integration`,
  },
  {
    id: "01122020",
    title: `S&T MOLD CONFIRMS THE HIGHEST STATUS IN HEWLETT PACKARD
    ENTERPRISE PARTNER PROGRAM`,
    date: "01.12.2020",
    description: `S&T Mold extended the status of Platinum Partner 2021 - the
    highest level of partnership, which implies a wide
    specialization`,
  },
  {
    id: "09122020",
    title: "S&T AG COMMUNICATES DETAILS OF VISION 2030",
    date: "09.12.2020",
    description: `Technology group S&T (ISIN AT0000A0E9W5, WKN A0X9EJ, SANT)
      today held its virtual Capital Market Day 2020 and provided
      more details of Vision 2030.`,
  },
  {
    id: "07072020",
    title: "S&T Mold invites you to take part in the partner webinar",
    date: "07.07.2020",
    description: `During this session, Aruba will briefly cover the importance of
      WPA2-Enterprise authentication and demonstrate a new
      cloud-based Aruba Instant ON solution.`,
  },
];

export const hpPosts = [
  {
    id: 1,
    title: "Test1",
    date: "test1",
    description: "Test description1",
    link: "wcs-hpe-alletrawcs-en-sntmd.swcontentsyndication.com/home",
    article: "1",
  },
  {
    id: 2,
    title: "Test2",
    date: "test2",
    description: "Test description2",
    link: "wcs-simplivity-hpwcs-en-sntmd.swcontentsyndication.com/home",
    article: "2",
  },
  {
    id: 3,
    title: "Test3",
    date: "test3",
    description: "Test description3",
    link: "wcs-pointnextservices-hpewcs-en-sntmd.swcontentsyndication.com/home",
    article: "3",
  },
];
